import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HashtagInfo } from '../models/hashtag-info.model';
import { HashtagInfoState } from './hashtag-info.reducer';

const selectHashtaginfoFeature =
    createFeatureSelector<HashtagInfoState>('hashtag-info');

export const selectHashtagInfo = (id: number) =>
    createSelector(selectHashtaginfoFeature, (state) =>
        getHashtagInfo(id, state.hashtags),
    );

export const selectDetailHashtagInfo = createSelector(
    selectHashtaginfoFeature,
    (state) =>
        state.detail.hashtagId
            ? getHashtagInfo(state.detail.hashtagId, state.hashtags)
            : undefined,
);

export const selectDetailVisible = createSelector(
    selectHashtaginfoFeature,
    (state) => state.detail.visible,
);

function getHashtagInfo(
    id: number,
    hashtags: Record<number, HashtagInfo>,
): HashtagInfo | undefined {
    return hashtags[id];
}
